<div class="prose flex justify-between">
    <h3 class="text-gray-700">Meus dados</h3>
</div>

<div>
    <div id="ult-login-id-telegram-container" class="flex flex-wrap gap-4">
        <div
            id="id-telegram-container"
            [ngClass]="{ 'w-full ': editarIdTelegram }"
            class="mt-2"
        >
            <div class="flex flex-col">
                <span class="text-2xs font-medium leading-4 text-gray-500"
                    >ID do telegram</span
                >
                <div
                    *ngIf="!carregando && !editarIdTelegram"
                    class="-mt-[2px] flex items-center gap-1"
                >
                    <span class="text-sm text-gray-900">{{
                        usuarioOriginal.idTelegram
                            ? usuarioOriginal.idTelegram
                            : "Não registrado"
                    }}</span>

                    <button
                        nz-button
                        nzType="text"
                        nzShape="circle"
                        nz-tooltip
                        nzTooltipTitle="Editar"
                        (click)="editarCampo('idTelegram')"
                    >
                        <span nz-icon nzType="edit"></span>
                    </button>
                </div>
                <div
                    *ngIf="carregando"
                    class="line-wave"
                    style="width: 100px; height: 10px"
                ></div>
            </div>
            <ng-container *ngIf="editarIdTelegram" class="mt-1 w-full">
                <nz-input-group
                    nzSearch
                    [nzSuffix]="suffixIcon"
                    [nzAddOnAfter]="suffixIconButton"
                    class="mt-1 w-full"
                >
                    <input
                        type="number"
                        nz-input
                        placeholder="Id Telegram"
                        [(ngModel)]="usuario.idTelegram"
                    />
                </nz-input-group>
                <ng-template #suffixIcon>
                    <span
                        nz-icon
                        nzType="close"
                        (click)="
                            editarCampo('');
                            usuario.idTelegram = usuarioOriginal.idTelegram
                        "
                        (keypress.enter)="
                            usuario.idTelegram = usuarioOriginal.idTelegram
                        "
                        tabindex="0"
                        nz-tooltip="Cancelar"
                        class="cursor-pointer"
                    ></span>
                </ng-template>
                <ng-template #suffixIconButton>
                    <button
                        nz-button
                        nzType="primary"
                        nzSearch
                        [nz-tooltip]="
                            usuario.idTelegram === usuarioOriginal.idTelegram
                                ? 'Nenhuma alteração detectada'
                                : 'Salvar'
                        "
                        (click)="salvarCampo('idTelegram')"
                        [disabled]="
                            usuario.idTelegram === usuarioOriginal.idTelegram
                        "
                    >
                        <span nz-icon nzType="save"></span>
                    </button>
                </ng-template>

                <button
                    nz-button
                    nzType="link"
                    (click)="abrirTelegramHelp()"
                    class="px-0"
                >
                    Como descobrir meu ID do Telegram?
                </button>
            </ng-container>
        </div>
        <div id="ultimo-login-container" class="flex flex-col">
            <span class="text-2xs font-medium leading-4 text-gray-500"
                >Últimos acessos</span
            >
            <div class="mt-1.5 flex flex-wrap gap-2">
                @for (acesso of $ultimosLogins(); track $index) {
                    <nz-tag class="m-0">{{
                        acesso.data | date: "dd/MM/yy HH:mm"
                    }}</nz-tag>
                }
            </div>
        </div>
    </div>
    <nz-divider></nz-divider>
    <div id="dados-govbr-container" class="flex flex-wrap gap-x-4 gap-y-3">
        <div class="flex w-full justify-between">
            <h3 class="font-semibold text-gray-700">
                Dados
                <span class="font-raleway font-extrabold">
                    <span class="text-blue-600">G</span>
                    <span class="text-yellow-500">o</span>
                    <span class="text-green-600">v</span>
                    <span class="text-blue-600">.b</span>
                    <span class="text-yellow-500">r</span>
                </span>
            </h3>

            <button
                nz-button
                nzType="primary"
                nzSize="small"
                (click)="atualizarDadosUsuario()"
            >
                <span nz-icon nzType="reload"></span>
                Atualizar
            </button>
        </div>
        <div
            id="nome-completo-container"
            *ngIf="!editarNomeCompleto"
            class="flex flex-col"
        >
            <span class="text-2xs font-medium leading-4 text-gray-500"
                >Nome completo</span
            >

            <span *ngIf="!carregando" class="text-sm text-gray-900">{{
                usuarioOriginal.nomeCompleto
            }}</span>

            <div
                *ngIf="carregando"
                class="line-wave"
                style="width: 200px; height: 10px"
            ></div>
        </div>

        <div id="cpf-container" *ngIf="!editarCpf" class="flex flex-col">
            <span class="text-2xs font-medium leading-4 text-gray-500"
                >CPF</span
            >

            <span *ngIf="!carregando" class="text-sm text-gray-900">{{
                usuarioOriginal.cpf
            }}</span>

            <div
                *ngIf="carregando"
                class="line-wave"
                style="width: 100px; height: 10px"
            ></div>
        </div>

        <div
            id="email-govbr-container"
            *ngIf="!editarEmail"
            class="flex flex-col"
        >
            <span class="text-2xs font-medium leading-4 text-gray-500"
                >E-mail govbr</span
            >

            <span *ngIf="!carregando" class="text-sm text-gray-900">{{
                usuarioOriginal.email
            }}</span>

            <div
                *ngIf="carregando"
                class="line-wave"
                style="width: 160px; height: 10px"
            ></div>
        </div>

        <div
            id="telefone-govbr-container"
            *ngIf="!editarTelefone"
            class="flex flex-col"
        >
            <span class="text-2xs font-medium leading-4 text-gray-500"
                >Telefone govbr</span
            >

            <span *ngIf="!carregando" class="text-sm text-gray-900">{{
                usuarioOriginal.celular
            }}</span>

            <div
                *ngIf="carregando"
                class="line-wave"
                style="width: 80px; height: 10px"
            ></div>
        </div>
    </div>
    <nz-divider></nz-divider>
    <div
        id="dados-usuario-orgao-container"
        class="flex flex-wrap gap-x-4 gap-y-3"
    >
        <h3 class="w-full font-semibold text-gray-700">Dados do servidor</h3>
        <div *ngIf="!editarOrgao" class="flex flex-col">
            <span class="text-2xs font-medium leading-4 text-gray-500"
                >Órgão</span
            >
            <div *ngIf="!carregando" class="flex items-center gap-1">
                <span class="text-sm text-gray-900">{{
                    usuarioOriginal.usuarioOrgaoPadrao.orgao.sigla
                }}</span>
                <mat-icon
                    *ngIf="podeAlterarDadosImutaveis && !souPrf"
                    class="descricao-edit-btn"
                    (click)="editarCampo('orgao')"
                >
                    edit</mat-icon
                >
            </div>
            <div
                *ngIf="carregando"
                class="line-wave"
                style="width: 200px; height: 10px"
            ></div>
        </div>
        <mat-form-field
            *ngIf="editarOrgao"
            class="full-width"
            appearance="outline"
        >
            <mat-label>Órgão</mat-label>
            <input
                matInput
                class=""
                placeholder="Órgão"
                [ngModel]="usuario.usuarioOrgaoPadrao.orgao.nome"
                (ngModelChange)="usuario.usuarioOrgaoPadrao.orgao.nome = $event"
            />
            <button
                mat-button
                matSuffix
                mat-icon-button
                (click)="editarCampo('')"
                matTooltip="Cancelar"
            >
                <mat-icon>cancel</mat-icon>
            </button>
            <button
                *ngIf="
                    usuario.usuarioOrgaoPadrao.orgao?.nome !==
                    usuarioOriginal.usuarioOrgaoPadrao.orgao?.nome
                "
                (click)="salvarCampo('orgao')"
                mat-button
                matSuffix
                mat-icon-button
            >
                <mat-icon>save</mat-icon>
            </button>
        </mat-form-field>

        <div *ngIf="!editarNomeGuerra" class="flex flex-col">
            <span class="text-2xs font-medium leading-4 text-gray-500"
                >Nome funcional (nome de guerra)</span
            >
            <div *ngIf="!carregando" class="flex items-center gap-1">
                <span class="text-sm text-gray-900">{{
                    usuarioOriginal.usuarioOrgaoPadrao.nomeFuncional
                }}</span>
                <mat-icon
                    *ngIf="podeAlterarDadosImutaveis && !souPrf"
                    class="descricao-edit-btn"
                    (click)="editarCampo('nomeGuerra')"
                    >edit</mat-icon
                >
            </div>
            <div
                *ngIf="carregando"
                class="line-wave"
                style="width: 120px; height: 10px"
            ></div>
        </div>
        <mat-form-field
            *ngIf="editarNomeGuerra"
            class="full-width"
            appearance="outline"
        >
            <mat-label>Nome funcional (nome de guerra) </mat-label>
            <input
                matInput
                class=""
                placeholder="Nome funcional"
                [(ngModel)]="usuario.usuarioOrgaoPadrao.nomeFuncional"
            />
            <button
                mat-button
                matSuffix
                mat-icon-button
                (click)="editarCampo('')"
                matTooltip="Cancelar"
            >
                <mat-icon>cancel</mat-icon>
            </button>
            <button
                *ngIf="
                    usuario.usuarioOrgaoPadrao.nomeFuncional !==
                    usuarioOriginal.usuarioOrgaoPadrao.nomeFuncional
                "
                (click)="salvarCampo('nomeGuerra')"
                mat-button
                matSuffix
                mat-icon-button
            >
                <mat-icon>save</mat-icon>
            </button>
        </mat-form-field>

        <div *ngIf="!editarUf" class="flex flex-col">
            <span class="text-2xs font-medium leading-4 text-gray-500"
                >UF de lotação</span
            >
            <div *ngIf="!carregando" class="flex items-center gap-1">
                <span class="text-sm text-gray-900">{{
                    usuarioOriginal.usuarioOrgaoPadrao.ufLotacao
                }}</span>
                <mat-icon
                    *ngIf="podeAlterarDadosImutaveis && !souPrf"
                    class="descricao-edit-btn"
                    (click)="editarCampo('uf')"
                    >edit
                </mat-icon>
            </div>
            <div
                *ngIf="carregando"
                class="line-wave"
                style="width: 22px; height: 10px"
            ></div>
        </div>

        <mat-form-field
            *ngIf="editarUf"
            class="full-width"
            appearance="outline"
        >
            <mat-label>UF de lotação</mat-label>
            <mat-select [(value)]="usuario.usuarioOrgaoPadrao.ufLotacao">
                <mat-option *ngFor="let uf of ufs" [value]="uf">{{
                    uf
                }}</mat-option>
            </mat-select>
            <!-- <input matInput class="" placeholder="UF de lotação" [(ngModel)]="usuario.uf.sigla"> -->
            <button
                mat-button
                matSuffix
                mat-icon-button
                (click)="editarCampo('')"
                matTooltip="Cancelar"
            >
                <mat-icon>cancel</mat-icon>
            </button>
            <button
                *ngIf="
                    usuario.usuarioOrgaoPadrao.ufLotacao !==
                    usuarioOriginal.usuarioOrgaoPadrao.ufLotacao
                "
                (click)="salvarCampo('ufSigla')"
                mat-button
                matSuffix
                mat-icon-button
            >
                <mat-icon>save</mat-icon>
            </button>
        </mat-form-field>

        <div *ngIf="!editarCargo" class="flex flex-col">
            <span class="text-2xs font-medium leading-4 text-gray-500"
                >Cargo</span
            >
            <div *ngIf="!carregando" class="flex items-center gap-1">
                <span class="text-sm text-gray-900">{{
                    usuarioOriginal.usuarioOrgaoPadrao.cargo
                }}</span>
                <mat-icon
                    *ngIf="podeAlterarDadosImutaveis && !souPrf"
                    class="descricao-edit-btn"
                    (click)="editarCampo('cargo')"
                    >edit</mat-icon
                >
            </div>
            <div
                *ngIf="carregando"
                class="line-wave"
                style="width: 150px; height: 10px"
            ></div>
        </div>
        <mat-form-field
            *ngIf="editarCargo"
            class="full-width"
            appearance="outline"
        >
            <mat-label>Cargo</mat-label>
            <input
                matInput
                class=""
                placeholder="Cargo"
                [(ngModel)]="usuario.usuarioOrgaoPadrao.cargo"
            />
            <button
                mat-button
                matSuffix
                mat-icon-button
                (click)="editarCampo('')"
                matTooltip="Cancelar"
            >
                <mat-icon>cancel</mat-icon>
            </button>
            <button
                *ngIf="
                    usuario.usuarioOrgaoPadrao.cargo !==
                    usuarioOriginal.usuarioOrgaoPadrao.cargo
                "
                (click)="salvarCampo('cargo')"
                mat-button
                matSuffix
                mat-icon-button
            >
                <mat-icon>save</mat-icon>
            </button>
        </mat-form-field>

        <div *ngIf="!editarMatricula" class="flex flex-col">
            <span class="text-2xs font-medium leading-4 text-gray-500"
                >Matrícula</span
            >
            <div *ngIf="!carregando" class="flex items-center gap-1">
                <span class="text-sm text-gray-900">{{
                    usuarioOriginal.usuarioOrgaoPadrao.matricula
                }}</span>
                <mat-icon
                    *ngIf="podeAlterarDadosImutaveis && !souPrf"
                    class="descricao-edit-btn"
                    (click)="editarCampo('matricula')"
                    >edit</mat-icon
                >
            </div>
            <div
                *ngIf="carregando"
                class="line-wave"
                style="width: 120px; height: 10px"
            ></div>
        </div>
        <mat-form-field
            *ngIf="editarMatricula"
            class="full-width"
            appearance="outline"
        >
            <mat-label>Matrícula</mat-label>
            <input
                matInput
                class=""
                placeholder="Matrícula"
                [(ngModel)]="usuario.usuarioOrgaoPadrao.matricula"
            />
            <button
                mat-button
                matSuffix
                mat-icon-button
                (click)="editarCampo('')"
                matTooltip="Cancelar"
            >
                <mat-icon>cancel</mat-icon>
            </button>
            <button
                *ngIf="
                    usuario.usuarioOrgaoPadrao.matricula !==
                    usuarioOriginal.usuarioOrgaoPadrao.matricula
                "
                (click)="salvarCampo('matricula')"
                mat-button
                matSuffix
                mat-icon-button
            >
                <mat-icon>save</mat-icon>
            </button>
        </mat-form-field>

        <div *ngIf="!editarEmail" class="flex flex-col">
            <span class="text-2xs font-medium leading-4 text-gray-500"
                >E-mail funcional</span
            >
            <div *ngIf="!carregando" class="flex items-center gap-1">
                <span class="text-sm text-gray-900">{{
                    usuarioOriginal.usuarioOrgaoPadrao.email
                }}</span>
                <mat-icon
                    *ngIf="podeAlterarDadosImutaveis && !souPrf"
                    class="descricao-edit-btn"
                    (click)="editarCampo('email')"
                >
                    edit</mat-icon
                >
            </div>
            <div
                *ngIf="carregando"
                class="line-wave"
                style="width: 160px; height: 10px"
            ></div>
        </div>
        <mat-form-field
            *ngIf="editarEmail"
            class="full-width"
            appearance="outline"
        >
            <mat-label>E-mail funcional</mat-label>
            <input
                matInput
                class=""
                placeholder="E-mail"
                [(ngModel)]="usuario.usuarioOrgaoPadrao.email"
            />
            <button
                mat-button
                matSuffix
                mat-icon-button
                (click)="editarCampo('')"
                matTooltip="Cancelar"
            >
                <mat-icon>cancel</mat-icon>
            </button>
            <button
                *ngIf="
                    usuario.usuarioOrgaoPadrao.email !==
                    usuarioOriginal.usuarioOrgaoPadrao.email
                "
                (click)="salvarCampo('email')"
                mat-button
                matSuffix
                mat-icon-button
            >
                <mat-icon>save</mat-icon>
            </button>
        </mat-form-field>
    </div>
</div>
